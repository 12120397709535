import React, { useState } from "react";

function Tab({ children }) {
  const [tab, setTab] = useState(0);

  if (React.Children.count(children) === 0) return <></>;

  return (
    <div className="w-full">
      <div className="fixed w-full bg-gray-100 flex shadow-lg">
        {React.Children.map(children, (child, idx) => {
          const btClass = "w-full uppercase text-lg p-4 transition-all";
          let tabClass = idx === tab
                ? "bg-pastelgreen text-white shadow-xl"
                : "bg-white hover:shadow-xl";
          if(idx === 0) tabClass += " rounded-bl-xl";
          if(idx === (React.Children.count(children) - 1)) tabClass += " rounded-br-xl";

          return (
            <button
              onClick={() => setTab(idx)}
              className={btClass + " " + tabClass}
            >
              {child.props.label}
            </button>
          );
        })}
      </div>
      <div className="overflow-scroll pt-20 px-2 md:px-6 lg:px-20">{children[tab]}</div>
    </div>
  );
}

export default Tab;
