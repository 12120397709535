const STATE = {
  Requested: 0,
  Started: 1,
  "Picked": 2,
  "OnWay": 3,
  "Delivered": 4
};




function OrderStatusElement({ orderState }) {
  let checkMarkCount = 0;

  if(orderState === 'STARTED') checkMarkCount = 1;
  else if(orderState === 'PICKED_UP') checkMarkCount = 2;
  else if(orderState === 'ON_THE_WAY' || orderState === 'READY_TO_DELIVER') checkMarkCount = 3;
  else if(orderState === 'ALREADY_DELIVERED') checkMarkCount = 4;

  let colorStyles = '';
  let borderStyles = '';

  return (<div className="w-full flex flex-wrap justify-between overflow-hidden">
            { (Object.keys(STATE).map((state, idx) => {

              colorStyles = idx > checkMarkCount ? 'bg-gray-400' : 'bg-pastelgreen';
              borderStyles = idx > checkMarkCount ? 'border-gray-400' : 'border-pastelgreen';

              const len = Object.keys(STATE).length;

              return (
                <>
		  <div className={`w-10 flex flex-col ${idx === 0 ? 'items-start' : idx === len-1 ? 'items-end' : 'items-center'}`}>
                    <div className={`w-10 h-10 rounded-full border-4 flex justify-center items-center ${borderStyles}`}>
	              <div className={`w-2 h-2 rounded-full ${colorStyles}`}></div>
                    </div>
                    <p className="text-xs"> { state } </p>
                  </div>
                  { idx !== (len - 1) && <div key={1+idx} className={`w-[10%] h-[2px] mt-5 rounded-md ${colorStyles}`}></div>}
                </>
              );})) }

      </div> );
}

export default OrderStatusElement;
