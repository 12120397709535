import { Link } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';

function Callback() {

  const urlSearchParams  = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams);


  (async() => {
    const urlParam = new URLSearchParams();
    urlParam.append('grant_type', 'authorization_code');
    urlParam.append('client_id', 'ARxlIzvRd7I0k4CNnIQQlqHdAb8lQVJ2');
    urlParam.append('client_secret', '_IVlVgd3sxg_E1TyO0APOFebr95FrxtreqvEhVEQ3N8sC7bOs0a8YoJhA5P9PM3x');
    urlParam.append('code', params.code);
    urlParam.append('redirect_uri', 'http://localhost:3001/callback');

    console.log(urlParam);

    const options = {
      method: 'POST',
      url: 'https://dev-oxy6vx7ejmssarqs.uk.auth0.com/oauth/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify(urlParam),
    };

    try {
      const res = await axios.request(options);
      localStorage.setItem('access_token', res.data.access_token);      
    } catch(e){
      console.log(e);
    }

  })();

  // localStorage.setItem("access_token", access_token);

  return <div>
           <p> redirecting... </p>
	   <Link to='/dashboard'> Click here </Link>
         </div> ;
}

export default Callback;
