import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import config from '../config';

async function getOrderById(orderId, accessToken) {
  const options = {
    method: 'GET',
      url: `${config.API_URL}/api/v1/order/${orderId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },    
  };

  const res = await axios.request(options);
  return res.data.order[0];
}

function useOrder(orderId, accessToken, disable){
  return useQuery({
    queryKey: ['order', orderId],
    queryFn: () => getOrderById(orderId, accessToken),
    enabled: !!orderId && !!accessToken,
    retry: false,
    retryOnMount: false,
    refetchInterval: 40000,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}

export default useOrder;
