import axios from "axios";
import { useState, useContext, useRef } from "react";
import RegContext from "./../context/RegContext";
import UserContext from './../context/UserContext';
import config from '../config';


function RegisterFirstPage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const nameRef = useRef();
  const phoneNumberRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setPage } = useContext(RegContext);
  const { setUser } = useContext(UserContext);

  const onClickRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (
        emailRef.current.value === "" ||
        passwordRef.current.value === "" ||
        confirmPasswordRef.current.value === "" ||
        nameRef.current.value === "" ||
        phoneNumberRef.current.value === "" ||
        addressRef.current.value === "" ||
        postcodeRef.current.value === ""
      ) {
        throw new Error("Field(s) should not be empty!");
      }

      if (passwordRef.current.value !== confirmPasswordRef.current.value)
        throw new Error("Password & Confirm Password didnt match!");

      const options = {
        method: "POST",
        url: `${config.API_URL}/auth/register`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailRef.current.value,
          password: passwordRef.current.value,
          name: nameRef.current.value,
          address: addressRef.current.value,
          phone_number: phoneNumberRef.current.value,
          postcode: postcodeRef.current.value
        },
      };

      const res = await axios.request(options);
      if(res.data){
        const newUser = {
          access_token: res.data.accessToken,
          user_id: res.data.userId,
          user_data: null
        };

        setUser(newUser);
        localStorage.setItem('access_token', newUser.access_token);
        localStorage.setItem('user_id', newUser.user_id);
      }      

    } catch (e) {
      console.error(e);
      if(e.response){
        console.log(e.response.data.errorList);
        const errorMsg = e.response.data.errorList.join(', ');
        console.log(errorMsg);
        setError(errorMsg);
      } else{
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className="w-full flex flex-col justify-center items-center gap-2 max-w-xs"
        action="#"
        method="GET"
      >
        <div className='mb-4 flex flex-col items-center'>
          <img alt="Delivery Club Logo" className='w-14 h-14' src="logo.png" />
	  <p className='text-lg'> Delivery Club </p>
        </div>        
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="email"
          type="text"
          placeholder="Email"
          ref={emailRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="password"
          type="password"
          placeholder="Password"
          ref={passwordRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="confirmpassword"
          type="password"
          placeholder="Confirm Password"
          ref={confirmPasswordRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="resName"
          type="text"
          minLength={4}
          maxLength={32}
          placeholder="Restaurant/Store Name"
          ref={nameRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="phoneNumber"
          type="tel"
          minLength={3}
          maxLength={15}
          placeholder="Phone Number"
          ref={phoneNumberRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="address"
          type="text"
          placeholder="Address"
          minLength={3}
          maxLength={64}
          ref={addressRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md"
          name="postcode"
          type="text"
          placeholder="Post Code"
          maxLength={12}
          ref={postcodeRef}
        />
	<div className="max-h-40 w-full flex justify-center items-center">
	  {error !== null && <p className='text-sm text-red-500 text-ellipsis'> { error } </p>}
        </div>
        <input
          className="w-full p-3 bg-pastelgreen hover:bg-forestgreen text-white rounded-md cursor-pointer transition-all"
          type="submit"
          disabled={isLoading}
          onClick={onClickRegister}
          value={isLoading ? "..." : "Register"}
        />

        <button
          onClick={() => {
            setPage(0);
          }}
        >
          Back to Login
        </button>
      </form>
    </>
  );
}

export default RegisterFirstPage;
