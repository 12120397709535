import axios from "axios";
import config from '../config';

async function removeOrder({userId, orderNumber, accessToken}) {
  const options = {
    method: 'DELETE',
    url: `${config.API_URL}/api/v1/user/${userId}/order/${orderNumber}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  };

  const res = await axios.request(options);
  return res.data.order_id;
}


export default removeOrder;
