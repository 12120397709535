import { useState } from "react";
import { all } from "axios";
import PRICE_MATRIX from "../../../data/priceMatrix";

export default function AddressSelection({
  restaurantPostcode,
  setAddress,
  setZone,
  selectedZone,
  setCode,
  onSearch,
}) {
  const [searchLoading, setSearchLoading] = useState(false);

  const onZoneSelect = (e) => {
    setZone(e.target.value || null);
  };

  const onCodeSelect = (e) => {
    setCode(e.target.value || null);
  };

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  // split the restaurant postcode into zone and code
  const restaurantZone = restaurantPostcode.split(" ")[0];
  const restaurantCode = restaurantPostcode.split(" ")[1];

  // get only string from restaurant zone
  const restaurantZoneString = restaurantZone.replace(/[0-9]/g, "");

  // get the price matrix of the restaurant zone string
  const restaurantPriceMatrix = PRICE_MATRIX[restaurantZoneString];

  


  // get all the possible address from the price matrix
  const allAddress = restaurantPriceMatrix.map((postcode, index) => {
    return postcode[0];
  });
  // remove the first element of the array
  allAddress.shift();


  // create two arrays, one for the zone and one for the code
  const zoneArray = [];

  // loop through all the address and push the zone and code to the array
  allAddress.forEach((address) => {
    const zone = address.split(" ")[0];

    // if the zone is not in the array, create assosiated array
    if (!zoneArray.includes(zone)) {
      zoneArray.push(zone);
      zoneArray[zone] = [];
    }

    // push the code to the zone array
    zoneArray[zone].push(address.split(" ")[1]);
  });
  

  return (
    <div className="w-full flex justify-between gap-2">
      <div className="flex flex-wrap items-center gap-1">
        <div className="w-16 md:w-20 flex flex-col">
          <select
            id=""
            name="zone"
            className="text-center p-2 outline-none bg-gray-50/90 rounded-md"
            onChange={onZoneSelect}
          >
            <option value=""></option>
            { zoneArray.map((zone, index) => {
              return <option key={index} value={zone}> {zone} </option>
            })}
          </select>
        </div>

        <div className="w-12 md:w-16 flex flex-col">
          <select
            id=""
            name=""
            className="text-center p-2 outline-none bg-gray-50/90 rounded-md"
            onChange={onCodeSelect}
          >
            <option value=""></option>
            { selectedZone && zoneArray[selectedZone].map((code, index) => {
              return <option key={index} value={code}> {code} </option>
            })}
          </select>
        </div>

        <div className="w-10 md:w-16 flex flex-col">
          <input
            type="text"
            className="text-center p-2 outline-none bg-gray-50/90 rounded-md"
            placeholder="EU"
            onChange={onChangeAddress}
          />
        </div>
      </div>
      <div>
        <button
          className="bg-[#4CAF50] rounded-md px-4 py-2 text-white disabled:bg-gray-300 disabled:animate-pulse"
          disabled={searchLoading}
          onClick={async (_e) => {
            setSearchLoading(true);
            await onSearch();
            setSearchLoading(false);
          }}
        >
          Search
        </button>
      </div>
    </div>
  );
}
