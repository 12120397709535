import { useEffect, useContext }from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from '../context/UserContext';

const defaultUser = {
  access_token: null,
  user_id: null,
  user_data: null
};

function Logout() {
  // const navigate = useNavigate();

  const { setUser } = useContext(UserContext);
  setUser(defaultUser);
  localStorage.removeItem('access_token');
  localStorage.removeItem('user_id');

  // useEffect(() => {
  //   if(user.access_token === null)
  //     navigate('/');
  // }, [user.access_token, navigate]);

  return <p>Logging out!</p>;
}

export default Logout;
