import { useContext, useState } from "react";

import UserContext from "../context/UserContext";
import Tab from "./Component/Tab";
import OrderList from "./Component/OrderList";
import RequestModal from "./Component/RequestModal";

import { BsPlusLg } from "react-icons/bs";

function HomePage() {
  const { user } = useContext(UserContext);
  const [tabStatus, setTabStatus] = useState(false);

  return (
    <div
      className={`w-full min-h-screen h-full bg-gray-50 pb-32 ${
        tabStatus === true ? "overflow-hidden" : ""
      }`}
    >
      {/* <div className="w-full p-4 bg-white shadow-lg"> */}
      {/*   <p className="text-xl">Welcome {user.user_data.name}!</p> */}
      {/*   <div className="flex justify-between items-center"> */}
      {/*     <p className="text-lg"> Orders </p> */}
      {/*   </div> */}
      {/* </div> */}

      <div className="">
        {tabStatus && (
          <RequestModal
            onCancelClick={() => {
              setTabStatus(false);
            }}
          />
        )}
        <Tab>
          <OrderList label="Current Jobs" />
          <OrderList label="Completed Jobs" showCompleted={true} />
        </Tab>
      </div>
      <div className="fixed right-5 bottom-28">
        <button
          className="group w-16 h-16 bg-pastelgreen rounded-full flex justify-center items-center shadow-2xl hover:scale-105 transition-all"
          onClick={() => {
            setTabStatus(true);
          }}
        >
          <BsPlusLg
            color="#ffffff"
            className="text-[30px] font-bold animate-pulse"
          />
        </button>
      </div>
    </div>
  );
}

export default HomePage;
