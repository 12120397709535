import { useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import UserContext from "../../../context/UserContext";
import queryClient from "../../../rquery/queryClient";
import useOrder from "../../../rquery/useOrder";
import removeOrder from "../../../rquery/removeOrder";

import { GoLocation } from "react-icons/go";
import { BsThreeDotsVertical, BsTelephoneFill } from "react-icons/bs";
import { MdNearbyError, MdNoEncryptionGmailerrorred } from 'react-icons/md';
import BarLoader from 'react-spinners/BarLoader';
import { toast } from 'react-toastify';

import OrderStatusElement from "./OrderStatusElement";
import OrderFooterDetails from "./OrderFooterDetails";
import ContextMenu from "../ContextMenu";

const initMenuPos = {
  show: false,
  x: 0,
  y: 0,
};

function OrderCard({ orderId, showCompleted }) {
  const { user } = useContext(UserContext);
  // const [hasError, setHasError] = useState(false);

  const {
    status,
    data: order,
    error,
    isFetching,
  } = useOrder(orderId, user.access_token);

  const {
    isLoading: isDeleting,
    error: deleteError,
    isSuccess: deleteSuccess,
    mutate: removeOrderMutate,
  } = useMutation({
    mutationFn: removeOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });

  const [menuPos, setMenuPos] = useState(initMenuPos);

  const onMenuOpen = (e) => {
    e.preventDefault();

    setMenuPos({
      show: true,
      x: e.pageX,
      y: e.pageY,
    });
  };

  const onMenuClose = () => {
    setMenuPos(initMenuPos);
  };

  const onConfirmDeleteClick = () => {
    onMenuClose();
    removeOrderMutate({
      userId: user.user_id,
      orderNumber: order.orderNumber,
      accessToken: user.access_token,
    });
  };

  if (status === "loading")
    return (
      <div className="w-full h-40 bg-white rounded-md p-4 flex justify-center items-center gap-2">
        <BarLoader color="#4CAF50" />
      </div>
    );

  if (error) {
    // setHasError(true);
    return <></>;
    // if (error?.response?.status === 401)
    //   return <div className="w-full h-40 bg-white rounded-md p-4 flex flex-col justify-center items-center gap-2">
    //            <MdNoEncryptionGmailerrorred className='text-red-500 text-[30px]'/>
	  //      <p> Not authorized! </p>
    //          </div>;

    // return (
    //   <div className="w-full h-40 bg-white rounded-md p-4 flex flex-col justify-center items-center gap-2">
    //     <MdNearbyError className='text-red-500 text-[30px]'/>
    //     { error?.message }
    //   </div>
    // );
  }

  const isOrderComplete = order.orderStatus.orderState === "ALREADY_DELIVERED";
  if (
    (showCompleted && !isOrderComplete) ||
    (!showCompleted && isOrderComplete)
  )
    return <></>;

    
  return (
    <>
      {menuPos.show && (
        <ContextMenu x={menuPos.x} y={menuPos.y} onClose={onMenuClose}>
          {!showCompleted && (
            <button
              className="w-full text-sm text-white p-2 px-8 bg-red-500 hover:bg-red-700 rounded-md"
              onClick={onConfirmDeleteClick}
            >
              Delete
            </button>
          )}
          {showCompleted && (
            <button className="w-full text-sm text-white p-2 px-8 bg-pastelgreen rounded-md">
              Order already completed!
            </button>
          )}
        </ContextMenu>
      )}
      <div className="w-full bg-white rounded-md p-4 flex flex-col gap-2 shadow-md">
        <div className="w-full flex gap-2 justify-between">
          <div className="w-full flex gap-2 items-center">
            <GoLocation size="18" color="#4CAF50" />
            <p className="text-md text-gray-500"> {order.customer.address} </p>
          </div>
          <div className="w-full flex gap-2 justify-end items-center">
            <div className="p-1 px-4 text-md text-white bg-pastelgreen rounded-md">
              {order.orderStatus.orderState.replace('_', ' ')}
            </div>
            <BsThreeDotsVertical
              className="cursor-pointer hover:bg-gray-200 rounded-sm"
              onClick={onMenuOpen}
            />
          </div>
        </div>

        <div className="flex gap-2 justify-between items-center">
          <div className="p-1 px-4 text-md text-[#F2AB58] bg-[#FEF7EC] rounded-md">
            {order.orderStatus.orderState.replace('_', ' ')}
          </div>
          <p className="text-gray-500"> {order.orderNumber} </p>
        </div>

        <div className="w-full h-[1px] bg-gray-200 rounded-md"></div>

        <OrderStatusElement
          key={order.orderStatus.orderState}
          orderState={order.orderStatus.orderState}
        />

        <div className="h-2 flex items-center">
          {/* {isFetching && (
            <div className="w-full flex justify-center">
              <BarLoader color="#4CAF50" />
            </div>
          )} */}
        </div>

        <OrderFooterDetails
          activityLog={order.activityLog}
          assignedCarrier={order.assignedCarrier}
          orderStatus={order.orderStatus}
        />
      </div>
    </>
  );
}

export default OrderCard;
