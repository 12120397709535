import { useContext } from 'react';

import UserContext from '../context/UserContext';

function HelpPage(){
  const { user } = useContext(UserContext);


  return (
        <div className="w-full h-screen bg-gray-50">
          <div className='w-full p-8 bg-white'>
	    <p className='text-xl'>
              Welcome {user.user_data.name}!
            </p>
	    <div className='flex justify-between items-center'>
              <p className='text-lg'> Help </p>
            </div>
          </div>

	  <div className='px-8 lg:px-8 flex flex-col mt-10'>
	    <p> Email: <a className='text-blue-500 cursor-pointer' href="mailto:deliveryclub@gmail.com"> deliveryclub@gmail.com </a></p>
	    <p> Contact Us: <a className='text-blue-500 cursor-pointer' href="tel:7944185084"> +44 07944185084 </a></p>
          </div>

    </div>
  );
}


export default HelpPage;
