import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "../config";

async function getQueryOrders(accessToken, sort, date, status, start, end) {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        url: `${config.API_URL}/api/v1/order/query`,
        params: {
            sort: sort,
            date: date,
            status: status,
            start: start,
            end: end
        }
    };

    const res = await axios.request(options);
    return res.data.orders;
}

export default function useQueryOrders({ sort, date, status, start, end }) {
    const accessToken = localStorage.getItem("access_token");
    const { data, isLoading, error } = useQuery(
        ["orders", sort, date],
        () => getQueryOrders(accessToken, sort, date, status, start, end),
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );

    return { data, isLoading, error };
}