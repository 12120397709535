import { useState, createContext } from "react";
import RegisterFirstPage from "./RegisterFirstPage";

import RegContext from "../context/RegContext";

function RegisterPage({ setPage }) {
  return (
    <RegContext.Provider value={{ setPage }}>
      <div className="w-screen h-screen flex justify-center items-center">
        <RegisterFirstPage/>
      </div>
    </RegContext.Provider>
  );
}

export default RegisterPage;
