import "./App.css";

import React, { useState, useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isTokenExpired } from './helpers/jwt';


import { getUserById } from './services/user_service';
import UserContext from "./context/UserContext";
import AuthPage from "./pages/AuthPage";
import DashboardPage from "./pages/DashboardPage";

import BarLoader from "react-spinners/BarLoader";

const defaultUser = {
  access_token: null,
  user_id: null,
  user_data: null,
};

function App() {
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token") ?? null;
    const userId = localStorage.getItem("user_id") ?? null;
    if(!accessToken || isTokenExpired(accessToken)) return;
    const newUser = { access_token: accessToken, user_id: userId };
    setUser(newUser);
  }, []);

  const {
    isFetching,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUserById(user.user_id, user.access_token),
    onSuccess: (data) => {
      let newUser = user;
      newUser.user_data = data;
      setUser(newUser);
    },
    onError: (error) => {
      console.log(error.message);
    },
    enabled: (!!user?.user_id && !!user?.access_token),
    retry: 1
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {isFetching && (
        <div className="fixed w-screen h-screen bg-gray-900/10 flex flex-col gap-2 justify-center items-center">
          <BarLoader color="#4CAF50" />
          Checking credentials...
        </div>
      )}
      {user.access_token && user.user_data ? (
        <DashboardPage />
      ) : (
        <AuthPage />
      )}
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
