import axios from 'axios';
import config from '../config';

async function getUserById(userId, accessToken) {
  const options = {
    method: 'GET',
      url: `${config.API_URL}/api/v1/user/${userId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },    
  };

  const res = await axios.request(options);
  return res.data.user;
}

export { getUserById };
