import React, { useState } from "react";

function BottomNav({ children }) {
  const [tab, setTab] = useState(0);

  if (React.Children.count(children) === 0) return <></>;

  return (
    <>
      <div className="">{children[tab]}</div>
      <div className="w-full fixed z-20 bottom-0 rounded-t-2xl overflow-clip shadow-2xl">
        <div className="bg-white flex gap-4 rounded-l-2xl">
          {React.Children.map(children, (child, idx) => {
            const tabClass = `w-full py-4 flex flex-col items-center gap-2 transition-all bg-white hover:bg-forestgreen hover:text-white ${
              tab === idx ? "text-[#4CAF50]" : ""
            }`;

            return (
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  console.log("asfdas");
                  setTab(idx);
                }}
                className={tabClass}
              >
                {child.props.icon}
                {child.props.label}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BottomNav;
