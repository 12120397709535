import BottomNav from "./Component/BottomNav";
import Home from "./Home";
import AccountPage from "./AccountPage";
import HelpPage from "./HelpPage";

import { RiHomeLine } from "react-icons/ri";
import { BsPlusLg, BsChatSquare } from "react-icons/bs";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";

function DashboardPage() {
  return (
    <BottomNav>
      <Home label={<p> Home </p>} icon={<RiHomeLine size={24} />} />
      <Home label={<p> New Job </p>} icon={<BsPlusLg size={24} />} />
      <HelpPage label={<p> Help </p>} icon={<BsChatSquare size={24} />} />
      <AccountPage
        label={<p> Account </p>}
        icon={<AiOutlineUser size={24} />}
      />
    </BottomNav>
  );
}

export default DashboardPage;
