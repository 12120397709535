const loughboroughPriceMatrix = [
  ["N/A",    "LE11 1", "LE11 2", "LE11 3", "LE11 4", "LE11 5", "LE12 5", "LE12 6", "LE12 7", "LE12 8", "LE12 9"],
  ["LE11 1",   "4.50",   "5.00",   "5.00",   "5.00",   "5.00",  "10.00",  "12.00",  "12.00",  "12.00",  "10.00"],
  ["LE11 2",   "5.00",   "4.50",   "5.00",   "5.00",   "5.00",  "10.00",  "12.00",  "12.00",  "12.00",  "10.00"],
  ["LE11 3",   "5.00",   "4.50",   "4.50",   "4.50",   "5.00",  "10.00",  "12.00",  "12.00",  "12.00",  "10.00"],
  ["LE11 4",   "5.50",   "5.00",   "4.50",   "4.50",   "4.50",  "10.00",  "12.00",  "12.00",  "12.00",  "10.00"],
  ["LE11 5",   "5.00",   "5.00",   "5.00",   "4.50",   "4.50",  "10.00",  "12.00",  "12.00",  "12.00",  "10.00"],
  ["LE12 5",  "10.00",  "10,00",  "10.00",  "10.00",  "10.00",   "4.50",   "5.00",   "5.00",   "5.00",   "5.00"],
  ["LE12 6",  "12.00",  "12.00",  "12.00",  "12.00",  "12.00",   "5.00",   "4.50",   "5.00",   "5.00",   "5.00"],
  ["LE12 7",  "12.00",  "12.00",  "12.00",  "12.00",  "12.00",   "5.00",   "5.00",   "4.50",   "5.00",   "5.00"],
  ["LE12 8",  "12.00",  "12.00",  "12.00",  "12.00",  "12.00",   "5.00",   "5.00",   "5.00",   "4.50",   "5.00"],
  ["LE12 9",  "10.00",  "10.00",  "10.00",  "10.00",  "10.00",   "5.00",   "5.00",   "5.00",   "5.00",   "4.50"],
];

export default loughboroughPriceMatrix;