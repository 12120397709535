import axios from "axios";
import config from "../config";

async function getPredictions(input) {
  if (!input) throw new Error("Empty input!");

  const options = {
    method: "GET",
    url: `${config.API_URL}/api/v1/place/search`,
    params: {
      input,
    },
  };

  const response = await axios.request(options);
  if (response.status === "fail")
    throw new Error("Error with message: " + response.data?.errors[0]);
  return response.data.predictions;
}

export { getPredictions };
