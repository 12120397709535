import { useContext, useState, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import OrderCard from './OrderCard';
import useListOrders from '../../rquery/useListOrders';
import useQueryOrders from '../../rquery/useQueryOrders';

function OrderList({ showCompleted }) {
  const { user } = useContext(UserContext);
  const orders = user.user_data.orders;

  const defaultDate = new Date().toISOString().split('T')[0];
  const [filterDate, setFilterDate] = useState(defaultDate);

  const { data: filteredOrders, isLoading, error } = useQueryOrders({ date: filterDate });
  const filteredOrderNumbers = filteredOrders?.map((o) => o.orderNumber);

  const totalOrders = !filteredOrders ? 0 : filteredOrders.length;
  const totalDeliveryFees = !filteredOrders ? 0 : filteredOrders?.reduce((acc, o) => acc + o.deliveryFee, 0);
  const totalDistance = !filteredOrders ? 0 : filteredOrders?.reduce((acc, o) => acc + o.distance, 0).toFixed(2);

  
  if (!showCompleted) {
    return <div className='flex flex-wrap gap-4'>
      {orders.map((o) => (
        <OrderCard key={o} orderId={o} showCompleted={showCompleted} />
      ))}
    </div>
  }

  if(isLoading) return <div>Loading...</div>
  if(error) return <div>Error: {error.message}</div>

  return (
    <>
        <div className='flex justify-between items-center mb-4'>
          <div>
            <p className='text-xl font-bold'>Total Orders: {totalOrders}</p>
            <p className='text-xl font-bold'>Total Distance: {totalDistance} miles</p>
            <p className='text-2xl font-bold'>Total Delivery Fees: &pound; {totalDeliveryFees}</p>            
          </div>
          <div className='flex flex-row gap-2 items-center'>
            <label htmlFor='filterDate' className='text-xl font-bold'>Date</label>
            <input id='filterDate' type='date' className='border-2 border-gray-300 rounded-md p-1' value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
          </div>
        </div>

      <div className='flex flex-wrap gap-4'>
        {filteredOrderNumbers?.map((o) => (
          <OrderCard key={o} orderId={o} showCompleted={showCompleted} />
        ))}
      </div>
    </>
  );

}


export default OrderList;