import { useContext } from 'react';

import UserContext from '../context/UserContext';
import queryClient from '../rquery/queryClient';

const defaultUser = {
  access_token: null,
  user_id: null,
  user_data: null,
};

function AccountPage(){
  const { user, setUser } = useContext(UserContext);

  const onLogoutClick = async () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    setUser(defaultUser);

    await queryClient.invalidateQueries({ queryKey: ["user"] });
  };

  return (
        <div className="w-full h-screen bg-gray-50">
          <div className='w-full p-8 bg-white'>
	    <p className='text-xl'>
              Welcome {user.user_data.name}!
            </p>

	    <div className='flex justify-between items-center'>
              <p className='text-lg'> Account </p>
            </div>
          </div>

          <button
            className="bg-red-500 p-2 px-4 ml-2 rounded-md text-white"
            onClick={onLogoutClick}
          >
            Logout
          </button>          
          
    </div>
  );
}


export default AccountPage;
