import { useState } from 'react';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';

function AuthPage(){
  const [page, setPage] = useState(0);
  
  if(page === 0)
    return <LoginPage setPage={setPage}/>;
  else 
    return <RegisterPage setPage={setPage}/>;
}


export default AuthPage;
