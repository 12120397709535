import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import config from "../../config";
import UserContext from "../../context/UserContext";
import queryClient from "../../rquery/queryClient";
import addOrder from "../../rquery/addOrder";
import { getPredictions } from "../../services/map_service";
import Tab from "./Tab";
import AddressSelection from "./AddressSelection";
import { GoLocation, GoPackage, GoCircleSlash } from "react-icons/go";
import { toast } from "react-toastify";
import loughboroughPriceMatrix from "../../data/loughboroughPriceMatrix";

import PlacesAutocomplete, {
  getcodeByAddress,
} from "react-places-autocomplete";

Number.prototype.pad = function (size) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
};

function RequestModal({ onCancelClick }) {
  const customerPhoneNumberRef = useRef();
  const specialInstructionsRef = useRef();

  const houseNumberRef = useRef();
  const [fieldError, setFieldError] = useState();
  const { user } = useContext(UserContext);

  const [zone, setZone] = useState(null);
  const [code, setCode] = useState(null);
  const [address, setAddress] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const deliveryFee = useMemo(() => {
    // get user postcode 
    const userPostCode = user.user_data.postcode;
    // get user zone and code
    const userZone = userPostCode.split(" ")[0];
    const userZoneCode = userPostCode.split(" ")[1].replace(/\D/g, "");

    // if customer postcode is empty, return 0.0    
    if (!zone || !code) return 0.0;

    // // get customer postcode
    const customerPostCode = `${zone} ${code}${address}`;
    // // get customer zone and code
    const customerZone = customerPostCode.split(" ")[0];
    const customerZoneCode = customerPostCode.split(" ")[1].replace(/\D/g, "");
    
    // return if user zone and customer zone are not same
    // if (userZone !== customerZone) return 0.0;

    const fee = loughboroughPriceMatrix[userZoneCode][customerZoneCode];

    return fee;
  }, [user.user_data.postcode, zone, code, address]);

  const pickUpTimeList = [30, 40, 50, 60];
  const [min, setMin] = useState(pickUpTimeList[0]);

  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutate: addOrderMutate,
  } = useMutation({
    mutationFn: addOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      onCancelClick();
    },
  });

  const onPostalSearch = async () => {
    let errorMessage = "";
    if (!zone) errorMessage = "Required Zone Field shouldnt be empty!";
    if (!code) errorMessage = "Required Code Field shouldnt be empty!";
    if (!address) errorMessage ="Required Address Field shouldnt be empty!";

    if (errorMessage) {
      setFieldError(errorMessage);
      toast.error(errorMessage);
      return;
    } else {
      setFieldError(null);
    }

    const customerPostCode = `${zone} ${code}${address} `;
    const lists = await getPredictions(customerPostCode);

    if (lists.length !== 0) {
      setCustomerAddress(
        `${houseNumberRef.current.value} ${lists[0].description}`
      );
      setAddressError(null);
    } else {
      const errorMsg = "Couldn't find any valid address for given postcode!";
      toast.error(errorMsg);
      setAddressError("Couldn't find any valid address for given postcode!");
      setCustomerAddress("");
    }
  };

  const onClickRequest = async () => {
    let errorMessage = "";    
    if (!zone) errorMessage = "Required Zone Field shouldnt be empty!";
    if (!code) errorMessage = "Required Code Field shouldnt be empty!";
    if (!houseNumberRef.current.value) errorMessage = "Required House or Flat Number Field shouldnt be empty!";
    if (!customerPhoneNumberRef.current.value) errorMessage = "Required Customer Number Field shouldnt be empty!";
    if (!customerAddress) errorMessage = "Required Customer Address Field shouldnt be empty!";

    if (errorMessage) {
      toast.error(errorMessage);
      setFieldError(errorMessage);
      return;
    } else {
      setFieldError(null);
    }

    const now = new Date();
    now.setMinutes(now.getMinutes() + min - 60);
    const pickupTime = `${now.getHours().pad(2)}:${now.getMinutes().pad(2)}:00`;
    now.setMinutes(now.getMinutes() + 4);
    const expectedDeliveryTime = `${now.getHours().pad(2)}:${now.getMinutes().pad(2)}:00`;    

    const newOrder = {
      customerAddress: customerAddress,
      customerPhoneNumber: customerPhoneNumberRef.current.value,
      expectedPickupTime: pickupTime,
      expectedDeliveryTime: expectedDeliveryTime,
      restaurantName: user.user_data.name,
      restaurantAddress: user.user_data.address,
      restaurantPhoneNumber: user.user_data.phone_number,
      deliveryFee: deliveryFee,
      deliveryInstruction: specialInstructionsRef.current.value,
    };

    addOrderMutate({ newOrder, accessToken: user.access_token });
  };

  return (
    <div className="fixed z-50 py-6 left-0 top-0 w-full h-full overflow-auto bg-gray-50/50">
      <div className="bg-white rounded-md m-auto p-6 b-2 w-[95%] max-w-2xl">

        {/* ModalContent */}
        <div className="w-full flex justify-between items-center mb-4">
          <p className="text-xl"> New Request </p>
          <button
            className="p-3 px-8 bg-red-500 text-white rounded-md"
            onClick={onCancelClick}
          >
            Cancel
          </button>
        </div>
        <div className="mb-2">
          <h4 className="w-full flex"> Pick up location </h4>
          <div className="" label="Postcode">
            <p className="w-full p-2 flex gap-2 items-center">
              <GoPackage size="24" color="#4CAF50" />
              {`${user.user_data.name} - ${user.user_data.address}`}
            </p>
            <div className="relative">
              <div
                className={`absolute w-[3px] ml-[19px] bg-green-500 rounded-md ${
                  customerAddress ? "h-6" : "h-3"
                }`}
              ></div>
              <div className="h-6 w-[3px] ml-[19px] bg-gray-300 rounded-md"></div>
            </div>
            <div className="w-full p-2 flex gap-2 items-center">
              {customerAddress && (
                <>
                  <GoLocation size="24" color="#4CAF50" />
                  <p className="font-bold text-pastelgreen">
                    {customerAddress}
                  </p>
                </>
              )}
              {!customerAddress && (
                <GoCircleSlash size="24" className="text-red-500" />
              )}
              {addressError && (
                <p className="text-red-500 text-xs"> {addressError}</p>
              )}
            </div>
          </div>
        </div>
        <div className="mb-2">
          <h4 className="w-full flex"> Delivery Zone </h4>
          {/* <Tab> */}
          <div className="w-full flex flex-wrap gap-2" label="By Postcode">
            <div className="text-sm w-20">
              <label htmlFor="houseNo" className="text-xs">
                House/Flat No. <span className="text-red-500">*</span>
              </label>
              <input
                ref={houseNumberRef}
                name="houseNo"
                type="text"
                min={1}
                max={4}
                placeholder="37"
                className="w-full text-center p-2 outline-none bg-gray-50/90 rounded-md"
              />
            </div>
            <div className="flex-1 text-sm">
              <label htmlFor="addressSelection">
                PostCode <span className="text-red-500">*</span>
              </label>
              <AddressSelection
                id={"addressSelection"}
                restaurantPostcode={user?.user_data?.postcode}
                setAddress={setAddress}
                setCode={setCode}
                setZone={setZone}
                selectedZone={zone}
                onSearch={onPostalSearch}
              />
            </div>
          </div>
          <div label="By Address">
            <PlacesAutocomplete
              value={customerAddress}
              onChange={(address) => {
                setCustomerAddress(address);
              }}
              onSelect={(address) => {
                setCustomerAddress(address);
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <label htmlFor="Address" className="text-base">
                    Address
                  </label>
                  <input
                    {...getInputProps({
                      placeholder: "Enter Customer Location....",
                      className:
                        "w-full text-center p-2 outline-none bg-gray-50/90 rounded-md",
                    })}
                  />

                  <div>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? "text-blue-500"
                        : "text-black";
                      return (
                        <div {...getSuggestionItemProps(suggestion)}>
                          <span className={style}>
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          {/* </Tab> */}
          <div className="h-1 w-full bg-[#4CAF50] my-4 rounded-md"></div>
        </div>
        <div className="mb-2">
          <h4 className="w-full flex">
            Pickup Time <span className="text-red-500">*</span>
          </h4>
          <div className="w-full flex justify-between gap-2 mt-2">
            {pickUpTimeList.map((p, index) => (
              <button
                key={index}
                className={`flex-1 w-20 px-4 py-2 text-white rounded-md ${
                  min === p ? "bg-pastelgreen shadow-lg" : "bg-gray-400"
                }`}
                onClick={(e) => {e.preventDefault(); setMin(p); }}
              >
                {p} min
              </button>
            ))}
          </div>
        </div>
        <div className="mb-2">
          <h4 className="w-full flex">
            Customer/Receiver Phone Number
            <span className="text-red-500">*</span>
          </h4>
          <input
            ref={customerPhoneNumberRef}
            name="customerPhoneNumber"
            type="tel"
            minLength={9}
            maxLength={14}
            className="w-full text-center p-2 outline-none bg-gray-50/90 rounded-md"
            placeholder="Required if you like to send delivery tracking link"
          />
        </div>
        <div className="">
          <h4 className="w-full flex"> Special Instructions </h4>
          <textarea
            ref={specialInstructionsRef}
            className="w-full p-2 outline-none bg-gray-50/90 rounded-md"
            rows="1"
            placeholder="Please enter any special message for the driver here."
          ></textarea>
        </div>
        <div className="w-full text-lg flex justify-between mb-2 font-bold text-yellow">
          <h3> Delivery Cost </h3>
          <h4> &pound; {deliveryFee} </h4>
        </div>
        <div className="h-6 my-2 w-full">
          {fieldError && <p className="text-red-500 text-xs"> {fieldError} </p>}
          {isError && <p className="text-red-500 text-xs"> {error.message} </p>}
          {isSuccess && (
            <p className="text-pastelgreen"> Order placed successfully. </p>
          )}
        </div>
        <div className="mb-2">
          <button
            onClick={onClickRequest}
            disabled={isLoading}
            className="w-full p-3 bg-pastelgreen text-white rounded-md"
          >
            {isLoading ? "Requesting... " : "Place Request "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RequestModal;
