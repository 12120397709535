import { useEffect, useState, useCallback } from "react";
import { BsTelephoneFill, BsSearch, BsHandThumbsUpFill } from "react-icons/bs";
import { GoPackage } from "react-icons/go";

export default function OrderFooterDetails({
  assignedCarrier,
  activityLog,
  orderStatus,
}) {
  const { orderState } = orderStatus;

  const formattedPickedTime = useCallback(() => {
    if(!activityLog.expectedPickupTime) return "00:00";
    const splitTime = activityLog.expectedPickupTime.split(":");
    const hour = parseInt(splitTime[0])+1;
    const minute = parseInt(splitTime[1]);

    // decrease 1 hours for timezone
    // pickedTime.setHours(pickedTime.getHours() + 1);
    return `${hour.pad(2)}:${minute.pad(2)}`;
  }, [activityLog.expectedPickupTime]);  

  const calcRemainPickup = useCallback(() => {
    const now = new Date();
    const pickupHM = formattedPickedTime().split(":");
    const expectedMinutes = parseInt(pickupHM[0]) * 60 + parseInt(pickupHM[1]);
    const currentMinutes = now.getHours() * 60 + now.getMinutes();
    return expectedMinutes - currentMinutes;
  }, [formattedPickedTime]);

  const [remainMin, setRemainMin] = useState(calcRemainPickup());

  // const [deliveryRemain, setDeliveryRemain] = useState();
  // console.log(activityLog.expectedPickupTime);
  // console.log("formattedPickedTime: ", formattedPickedTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainMin(calcRemainPickup());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [calcRemainPickup]);

  return (
    <div className="flex gap-2 justify-between">
      <div className="flex gap-2">
        <div className="flex rounded-full bg-gray-50 p-3 w-11 h-11 items-center">
          {assignedCarrier && orderState !== "ALREADY_DELIVERED" && (
            <a
              href={"tel:" + assignedCarrier?.phoneNumber}
              className="cursor-pointer"
            >
              <BsTelephoneFill className="w-5 h-5 text-[#4CAF50]" />
            </a>
          )}
          {assignedCarrier && orderState === "ALREADY_DELIVERED" && (
            <BsHandThumbsUpFill className="w-5 h-5 text-pastelgreen" />
          )}
          {!assignedCarrier && (
            <BsTelephoneFill className="w-5 h-5 text-gray-500" />
          )}
        </div>
        <div className="flex flex-col">
          {assignedCarrier && orderState === "STARTED" && (
            <>
              <p className="text-lg"> {assignedCarrier?.name} </p>
              <p>
                will arive
                <span className="text-pastelgreen font-bold">
                  {remainMin > 0 ? ` in ${remainMin} Min` : " ASAP"}
                </span>
              </p>
            </>
          )}

          {assignedCarrier && orderState === "PICKED_UP" && (
            <>
              <p className="text-lg"> {assignedCarrier?.name} </p>
              <p>
                picked up the <span className="text-pastelgreen font-bold">Package</span>
              </p>
            </>
          )}

          {assignedCarrier && orderState === "READY_TO_DELIVER" && (
            <>
              <p className="text-lg"> {assignedCarrier?.name} </p>
              <p>
                is delivering the{" "}
                <span className="text-pastelgreen font-bold">Package</span>
              </p>
            </>
          )}

          {!assignedCarrier && (
            <>
              <div className="flex gap-2">
                <BsSearch className="animate-pulse text-lg" />
                <p className="text-lg"> ----- ----- </p>
              </div>
              <p>
                {" "}
                Searching for <span className="text-pastelgreen font-bold">Drivers</span>
              </p>
            </>
          )}
        </div>
      </div>
      <div>
        {(orderState === "STARTED" ||
          orderState === "NOT_ASSIGNED" ||
          orderState === "NOT_STARTED_YET") && (
          <>
            <p> Exp. Pickup Time </p>
            <p className="text-gray-400">{formattedPickedTime()}</p>
          </>
          )}
        {(orderState === "READY_TO_DELIVER" ||
          orderState === "PICKED_UP") && (
          <>
            <p> Exp. Delivery Time </p>
            <p className="text-pastelgreen font-bold">{activityLog.expectedDeliveryTime}</p>
          </>
        )}
        
        {orderState === "ALREADY_DELIVERED" && (
          <>
            <p> Delivered on </p>
            <p className="text-pastelgreen font-bold">
              {new Date(activityLog.deliveryTime).toLocaleString()}
            </p>
          </>
        )}
      </div>
      {(orderState === "STARTED") && (
        <div
          className={`${remainMin < 0 ? "bg-red-500" : "bg-[#4CAF50]"}
                       flex flex-col items-center px-4 py-2 text-white font-bold rounded-md`}
        >
          <div> {remainMin} </div>
          <div> MIN </div>
        </div>
      )}
    </div>
  );
}
