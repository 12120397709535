import { useRef, useState, useContext } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

import UserContext from "../context/UserContext";
import config from '../config';

function LoginPage({ setPage }) {
  let emailRef = useRef();
  let passwordRef = useRef();

  const { setUser } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    const options = {
      method: "POST",
      url: `${config.API_URL}/auth/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      },
    };

    try {
      const res = await axios.request(options);

      if (res.data) {
        const newUser = {
          access_token: res.data.accessToken,
          user_id: res.data.userId,
          user_data: null,
        };

        setUser(newUser);
        localStorage.setItem("access_token", newUser.access_token);
        localStorage.setItem("user_id", newUser.user_id);
      }
    } catch (e) {
      if (e?.response?.data?.errorList) {
        const errorMsg = e.response.data.errorList.join(", ");
        setError(errorMsg);
        toast.error(errorMsg);
      } else {
        setError(e.message);
        toast.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">

      <form
        className="w-full flex flex-col justify-center items-center gap-2 max-w-xs"
        action="#"
        method="GET"
      >
        <div className='mb-4 flex flex-col items-center'>
          <img alt="Delivery Club Logo" className='w-14 h-14' src="logo.png" />
          {/* <p className='text-lg'> Delivery Club </p> */}
        </div>
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md transition-all"
          name="email"
          type="text"
          placeholder="Email"
          ref={emailRef}
        />
        <input
          className="w-full bg-gray-200/80 p-3 outline-none focus:ring-1 focus:ring-pastelgreen rounded-md transition-all"
          name="password"
          type="password"
          placeholder="Password"
          ref={passwordRef}
        />
        <div className="max-h-40 w-full flex justify-center items-center">
          {error !== null && (
            <p className="text-sm text-red-500 text-ellipsis"> {error} </p>
          )}
        </div>
        <input
          className="w-full p-3 bg-pastelgreen hover:bg-forestgreen text-white rounded-md cursor-pointer transition-all"
          disabled={isLoading}
          name=""
          type="submit"
          value={isLoading ? "..." : "Login"}
          onClick={login}
        />

        {/* <button
          className="w-full p-3 bg-red-500/90 hover:bg-red-600 text-white rounded-md cursor-pointer transition-all"
          onClick={() => setPage(1)}>
          Register Now
        </button> */}
        <p className="text-blue-600 cursor-pointer" onClick={() => {
          alert('Contact Us');
        }}> Forgot your credentials? </p>
      </form>
    </div>
  );
}

export default LoginPage;
