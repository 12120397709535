import { useEffect, useState } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import useWindowSize from '../../../hooks/useWindowSize';

function ContextMenu({ x, y, onClose, children }){
  const menuRef = useClickOutside(onClose);
  const [contextWidth, setContextWidth] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    setContextWidth(menuRef.current.clientWidth);
  }, [menuRef]);


  const total = x + contextWidth + 50;
  if(total > width) {
    const offset = total - width;
    x = x - offset;
  }

  return (
    <div ref={menuRef} className={`absolute transition-all bg-white border-2 rounded-md`}
         style={{
           top: `${y}px`,
           left: `${x}px`
         }}>
      { children }
    </div>
  );
}

export default ContextMenu;
